import {
	Card,
	CardContent,
	Typography,
	FormControl,
	FormControlLabel,
	Radio,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
	imageFolder
} from "../../constants/ImageConstants";
import "./style.scss";
import PropTypes from 'prop-types'

const CurrencyComponent = (props) => {
	const { t: getLabel } = useTranslation();
	return (
		<FormControl className="currency-form-controlar" sx={{ mb: 1 }}>
			<FormControlLabel
				value={props.currency}
				control={<Radio />}
				label={
					<Card data-testid="currencyCard">
						<CardContent>
							<img src={`${imageFolder}${props.icon}.png`} alt="icon" />
							<Typography data-testid="currencyCardLabel">
								{getLabel(props.currency)} <small>{getLabel(props.currencySub)}</small>
							</Typography>
						</CardContent>
					</Card>
				}
			/>
		</FormControl>
	);
};
CurrencyComponent.propTypes = {
  currency: PropTypes.string.isRequired,
  currencySub: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
}

export default CurrencyComponent;
