import React from "react";
import { Typography, Button, Container, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./style.scss";
import FXCMTrans from "../common/FXCMTrans";
import PropTypes from 'prop-types';

const UserMessage1 = (props) => {
	const { t: getLabel } = useTranslation();
	const { email, image, title, description, handleClick, buttonText } = props;

	return (
		<Box align="center" className="password-Box">
			<Container
				maxWidth={false}
				sx={{ maxWidth: "600px", wordWrap: "break-word" }}
			>
				<div className="password-box">
					<img src={image} alt="mailImg" className="cardIcon" />
					{email && (
						<Typography className="heading-1" sx={{ mt: "20px" }}>
							<FXCMTrans
								defaults={getLabel(title)}
								values={{ email }}
							/>
						</Typography>
					)}
					<Typography className="subtext-cards" sx={{ mt: "20px", maxWidth: "448px", }}>
						{getLabel(description)}
					</Typography>
					<Button
						variant="contained"
						color="primary"
						sx={{ mt: 3 }}
						onClick={handleClick}
					>
						{getLabel(buttonText)}
					</Button>
				</div>
			</Container>
		</Box>
	);
};

UserMessage1.propTypes = {
	email: PropTypes.string,
	image: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
	buttonText: PropTypes.string,
	handleClick: PropTypes.func
}

export default UserMessage1;