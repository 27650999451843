const { getAPIPath } = require("../../utils/endPointMapper");

const BASE_URL = getAPIPath().REACT_APP_BASE_URL;
const profileUrl = "en_UK";
const PAY_PAL_API = process.env.REACT_APP_PAYPAL_API;

module.exports = {
	BASE_URL,
	profileUrl,
	PAY_PAL_API,
};
