/* eslint-disable no-control-regex */
/* eslint-disable no-useless-escape */
/* istanbul ignore file */
export const HAVING_NUMERIC = /\d/; 
export const HAVING_UPPERCASE_LETTERS = /[A-Z]/;
export const HAVING_LOWERCASE_LETTERS = /[a-z]/;
export const CUSTOM_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // NOSONAR
export const ONLY_ALPHA_NUMERIC = /[^\u0000-\u007f]/; // NOSONAR
export const SPECIAL_REGEX_FOR_EMAIL = /^[a-zA-Z0-9](.*[a-zA-Z0-9])?$/;
export const CONSECUTIVE_REGEX = /^(?!.*[!#$%&'*+/=?^`{|}-]{2,})(?!.*[.-]{2,})(?!.*@.*@)(?!.*[-.]$)(?!.*\s)(?!.*fuhui)(?!.*FXCM)(?!.*[!#$%&'*+/=?^`{|}-]$)(?!.*\/{2,})(?!.*__)(?!.*[!#$%&'*+/=?^`{|}-]{2,})(?!.*\*_)^(?!.*[!#$%&'*+/=?^`{|}-]{2,})(?!.*_\*).*(?!.*\*_)(?!.*[_][!#$%&'*+/=?^_`{|}-]).*(?!.*[_][$!#%&'*+/=?^_`{|}-]).*(?!.*\*[_]).*(?!.*[_]\*)^(?!.*[!#$%&'*+/=?^`{|}-]{1}[_]).*(?!.*[_][$!#%&'*+/=?^_`{|}-]{1}).*(?!.*\*[_]{1}).*(?!.*[_]\*{1})[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:_[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/; // NOSONAR
export const CHECK_LENGTH_MIN_8_MAX_15 = /^.{8,15}$/; // NOSONAR
export const CERTAIN_SPECIAL_CHARECTORS = /[<>\\±§/+-]/; // NOSONAR
export const HAVING_SPACE = /\s/; // NOSONAR
export const HAVING_ALPHA_NUMERIC_SPACE = /^(\w+\s?)*\s*$/; // NOSONAR
export const ONLY_SPACE = /^([^ ]* ){1}[^ ]*$/; // NOSONAR
export const CHINA_UNIQUE_CODE = /^[\u4E00-\u9FFF\s]*$/; // NOSONAR
export const HAVING_ONLY_NUMBERS = /[^0-9]/; // NOSONAR
export const NAME_REGEX = /^((?:[A-Za-z]+ ?){1,3})$/ // NOSONAR
export const UNIT_NO_REGEX =/^[a-zA-Z0-9.'#@%&\/\s-]*[a-zA-Z0-9.'#@%&\/\-][a-zA-Z0-9.'#@%&\/\s-]*$/; // NOSONAR