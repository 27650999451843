import React, { useState, useEffect } from "react";
import {
	Grid,
	FormControl,
	Button,
	Box,
	Typography,
	TextField,
	IconButton,
	InputAdornment,
	FormControlLabel,
	Checkbox,
	FormHelperText
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { configurationServices } from "../../constants/configurationServices";
import { lockoutWarning, logInIcon } from "../../constants/ImageConstants";
import "./style.scss";
import Header from "../../components/layout/Header";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginService, systemAlertFunc } from "../../services/auth.service";
import { updateUserInfo } from "../../reducers/userInfoSlice";
import FXCMErrorHandling from "../../components/common/FXCMErrorHandling";
import { thresholdDataSave } from "../../services/threshold.service";
import { notifyApiFail } from "../../reducers/errorNotifySlice";
import { analytics } from "../../utils/analytics/analytics";
import { additionalProperties, languagequeryParam, setUserEntity } from "../../reducers/signUpSlice"
import useDirectionHook from "../../hooks/useDirectionHook";
import Cookies from 'js-cookie';
import { getPaymentStatusByFxowaccount } from '../../utils/getPaymentStatusByFxowaccount';
import { storelanguageList } from "../../reducers/commonSlice";
import { getAPIPath } from "../../utils/endPointMapper";
import { getHeaders } from "../../utils/getUsersDetails";
import { customAxios } from "../../services/serviceInterceptor";
import LoadingButton from "@mui/lab/LoadingButton";
import Loader from "../../components/Loader";

	
const LogInComponent = () => {//NOSONAR
	localStorage.removeItem("uPassToken");
	localStorage.removeItem("autoLoginMail");
	localStorage.removeItem("usermail");
	const BASE_URL = getAPIPath().REACT_APP_BASE_URL;
	const { t: getLabel } = useTranslation();
	const [config, setConfig] = useState();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [lockoutError, setLockoutError] = useState(false);
	const [invalidUser, setInvalidUser] = useState(false);
	const[buttonLoader, setButtonLoader] = useState(false);
	const { user } = useSelector((state) => state.user);
	const [countriesList, setCountriesList] = useState();
	const [platformList, setPlatformList] = useState();
	const [defaultValue, setDefaultValue] = useState();
	const [defaultTitle, setDefaultTitle] = useState();
	const { loginRes, authToken, userEntity, languageParam, getAllEnLabels, autoLoginResponse } = useSelector(
		(state) => state.signUp
	);
	const queryParameters = new URLSearchParams(window.location.search);
	let urlLang = queryParameters.get("lang");
	if (urlLang == "undefined" || urlLang == "null") {
		urlLang = "en-US";
	}
	dispatch(languagequeryParam(urlLang));
	const [direction] = useDirectionHook(urlLang || languageParam) //custom hook
	const [disableAll, setDisableAll] = useState(false);
	const [values, setValues] = useState({
		password: "",
		showPassword: false,
		email: "",
		isChecked: false,
	});
	const [error, setError] = useState({
		email: false,
		password: false,
	} );
	/* istanbul ignore next */
	useEffect(() => {
		let URL = localStorage.getItem("logoutRedirectURL");
		if (URL) {
			window.location.href = URL;
			localStorage.removeItem("logoutRedirectURL");
		}
	}, []);
	
	useEffect(() => {
		dispatch(additionalProperties({}))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setValues({
			...values,
			email: Cookies.get("username")
				? Cookies.get("username")
				: "",
			isChecked: false,
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [] );
	/* istanbul ignore next */
	const handleClickShowPassword = () => {
		setValues({
			...values,
			showPassword: !values.showPassword,
		});
	};
	/* istanbul ignore next */
	const handleClickRememberMe = () => {
		Cookies.set("username", values.email, { expires: 1 });
		setValues({
			...values,
			isChecked: !values.isChecked,
		});
	};
	/* istanbul ignore next */
	const handleForgotPassword = () => {
		analytics?.trackEvent("targeted_click", { engagementContext: "Forgot Password" });
		dispatch(additionalProperties(loginRes));
		navigate("/forgotpassword");
	};
	/* istanbul ignore next */
	const handleForgotUserName = () => {
		analytics?.trackEvent("targeted_click", { engagementContext: "Forgot Username" });
		dispatch(additionalProperties(loginRes));
		navigate("/forgotusername");
	};
	/* istanbul ignore next */
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};
/* istanbul ignore next */
	const fetchJson = () => {
		fetch(configurationServices(userEntity).LogInURL)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				setConfig(data);
			})
			.catch((e) => {
				console.log(e.message);
			});
	};
	useEffect(() => {
		fetchJson();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
/* istanbul ignore next */
	const fetchCountryJson = () => {
		let countriesList = configurationServices().countryList;
		fetch(countriesList)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				setCountriesList(data);
			})
			.catch((e) => {
				console.log(e.message);
			});
	};
	/* istanbul ignore next */
	const fetchPlatformJson = () => {
		let platformList = configurationServices(userEntity).platformURL;
		fetch(platformList)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				setDefaultTitle(data.SelectedValue)
				setDefaultValue(data.SelectedCode)
				setPlatformList(data.list);
			})
			.catch((e) => {
				console.log(e.message);
			});
	};
/* istanbul ignore next */
	useEffect(() => {
		fetchCountryJson();
		fetchPlatformJson();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
/* istanbul ignore next */
	const navigateToSpecificPage = (loginRes, user, authToken) => {// NOSONAR
		const currentStep = loginRes?.data["user-details"]?.userForms?.currentStep ? loginRes.data["user-details"].userForms.currentStep : "";
		const applicationStatus = loginRes?.data["user-details"]?.userForms?.applicationStatus ? loginRes?.data["user-details"]?.userForms?.applicationStatus : "";
		const loginResponsePKA = loginRes?.data["user-details"]?.pkainfo ? loginRes.data["user-details"].pkainfo : false;
		const pkaAllInfo = JSON.parse(loginResponsePKA);
		if (loginRes?.data["user-details"]?.entity !== 'FXCM_AU' && applicationStatus === "Complete") {
			navigate("/tax");
		} else if (!currentStep || currentStep === "") {
			navigate("/platform");
		} else if (
			currentStep === "FORM_OF_ID" ||
			currentStep === "TRADING_INFO" ||
			currentStep === "EMPLOYMENT_INFO" ||
			currentStep === "FINANCIAL_INFO" ||
			currentStep === "CONTACT_INFO" ||
			currentStep === "PERSONAL_INFO" ||
			currentStep === "DENOMINATION" ||
			(userEntity === "FXCM_AU" && currentStep === "PKA_QUIZ")
		) {
			const config = {
				...getHeaders(authToken, user)
			};
			customAxios.post(BASE_URL, {
				processName: "annualIncome",
				requestBody: {},
				methodType: "POST"
			}, config).then((response) => {
				setButtonLoader(false);
				let userAnnualIncomeList = [];
				let netWorthList = [];
				for (const [key1, value1] of Object.entries(
					response.data.data["annual-income-response"].annualIncome
				)) {
					userAnnualIncomeList.push({
						position: key1,
						value: value1,
					});
				}
				for (const [key1, value1] of Object.entries(
					response.data.data["annual-income-response"].netWorth
				)) {
					netWorthList.push({
						position: key1,
						value: value1,
					});
				}
				const thresholdList = { userAnnualIncomeList, netWorthList };
				dispatch(thresholdDataSave(thresholdList));
				navigate("/userInfo");
			}).catch((err) => {
				setButtonLoader(false);
				analytics?.trackEvent("login_error", { errorMessage: err });
				dispatch(notifyApiFail("SOMETHING_WENT_WRONG"));
                dispatch(systemAlertFunc(config?.headers?.requestid, err?.type, err?.message));
			});
		} else if (currentStep === "PLATFORM") {
			navigate("/currency-select");
		} else if (currentStep === "HIGH_RISK_INVESTMENT") {
			navigate("/termsofbusiness");
		} else if (currentStep === "QUALIFICATION_QUESTIONS") {
			navigate("/termsofbusiness");
		} else if (currentStep === "ONBOARDING_COMPLETE") {

			fetch(configurationServices(userEntity).languageListURL)
				.then((response) => { return response.json(); })
				.then((data) => {
					const newLangaugeList = Object.entries(data).map(([key, value]) => ({ key, value }));
					dispatch(storelanguageList(newLangaugeList));
					let langParam = newLangaugeList.filter((item) => item.key === localStorage.getItem("i18nextLng"));
					if (langParam.length > 0 && langParam[0]) {
						if (loginRes.data["user-details"].countryOfResidence === "DE") {
							dispatch(languagequeryParam(loginRes.data["user-details"].userLocale));
						} else {
							dispatch(languagequeryParam(localStorage.getItem("i18nextLng")));
						}
					}
				})
				.catch((e) => {
					console.log(e.message);
				});

			if (loginRes?.data["user-details"]?.userForms?.accountRejectionReason) {
				navigate("/rejection");
			} else {
				if (loginRes?.data?.["user-details"]?.fxowsAccountNumber && loginRes?.data?.["user-details"]?.["userForms"]?.fundingStatus?.toLowerCase() === "allowed") {
					getPaymentStatusByFxowaccount(
						user,
						loginRes?.data?.["user-details"]?.fxowsAccountNumber,
						navigate,
						authToken
					);
					
				} else {
					navigate("/docUpload");
				} 
			}
		} else if (loginRes.data["user-details"]?.userForms?.hrinTriggerStatus === true) {
			navigate("/riskdisclosure");
		} else if (
			(loginRes?.data["user-details"]?.entity === "FXCM_SVG" && currentStep === "FRIENDS_FAMILY_REFERAL" && loginRes.data["user-details"]?.userForms?.hrinStatus === false) ||
			(userEntity === "FXCM_UK" && currentStep === "TRADING_INFO" && loginRes.data["user-details"]?.userForms?.hrinStatus === false) ||
			(userEntity === "FXCM_EU" && currentStep === "PKA_QUIZ" && pkaAllInfo?.answer5 && loginRes.data["user-details"]?.userForms?.hrinStatus === false)
		) {
			navigate("/riskdisclosure");
		} else if (
			(loginRes?.data["user-details"]?.entity === "FXCM_SVG" && currentStep === "FRIENDS_FAMILY_REFERAL" && loginRes.data["user-details"]?.userForms?.hrinTriggerStatus === undefined) || (userEntity === "FXCM_UK" && currentStep === "TRADING_INFO" && loginRes.data["user-details"]?.userForms?.hrinTriggerStatus === undefined) || (userEntity === "FXCM_EU" && currentStep === "PKA_QUIZ" && pkaAllInfo?.answer5 && loginRes.data["user-details"]?.userForms?.hrinTriggerStatus === undefined)
		) {
			navigate("/termsofbusiness");
		} else if (currentStep === "MANUAL_REVIEW" || currentStep === "DEPOSIT") {
			navigate("/tax");
		} else {
			navigate("/platform");
		}
	}
/* istanbul ignore next */
	const loginSuccessAnalyticsCall = (loginRes) => {
		let countryData;
		let platformData;
		if (countriesList) {
			countriesList.map(el => {
				if (el.country_code === loginRes.data["user-details"].countryOfResidence) {
					countryData = el.country_desc
				}
			})
		}
		platformList?.map(el => {//NOSONAR
			if (loginRes.data["user-details"].countryOfResidence === "FR") { //NOSONAR
				if (el.value === loginRes.data["user-details"].platform) {
					platformData = getAllEnLabels[el.title];
				}
			}
			else if (defaultValue === loginRes.data["user-details"].platform) {
				platformData = getAllEnLabels[defaultTitle];
			}
			else {
				if (el.value === loginRes.data["user-details"].platform) {
					platformData = [getAllEnLabels[defaultTitle], getAllEnLabels[el.title]];
				}
			}
		})
		analytics?.trackSingleEvent({ eventName: "login_success", eventDetails: { entity: loginRes?.data["user-details"]?.entity, formEmail: loginRes.data["user-details"].email, formCountry: countryData, currency: loginRes.data["user-details"]?.denomination, platform: platformData, username: loginRes.data["user-details"]?.username } });
	}
/* istanbul ignore next */
	useEffect(() => {
		if (loginRes?.success && loginRes?.data["user-details"] && user && !Object.hasOwnProperty(autoLoginResponse)) {

			let userInfoData = {
				...values,
				email: loginRes.data["user-details"].email,
				userName: loginRes.data["user-details"].username,
				countryOfResidence:
					loginRes.data["user-details"].countryOfResidenceCode || loginRes.data["user-details"].countryOfResidence,
				locale: loginRes.data["user-details"].userLocale,
				authToken: loginRes?.data?.["login-response"]?.token ? loginRes?.data?.["login-response"]?.token : token,

				fundingStatus: loginRes?.data["user-details"]?.userForms?.fundingStatus,
				preCddEligible: loginRes?.data["user-details"]?.preCddEligible,
				applicationStatus: loginRes?.data["user-details"]?.userForms?.applicationStatus,
				crsStatus: loginRes?.data["user-details"]?.userForms?.crsStatus,
				fxowsAccountNumber: loginRes?.data["user-details"]?.fxowsAccountNumber,
				completedSteps:loginRes?.data["user-details"]?.userForms?.completedSteps,
				currentStep:loginRes?.data["user-details"]?.userForms?.currentStep,
				kycStatus: loginRes?.data["user-details"]?.userForms?.kycStatus
			};
			dispatch(updateUserInfo(userInfoData));

			if (user?.authToken) {
				Cookies.set("token", loginRes?.data?.["login-response"]?.token);
				dispatch(languagequeryParam(loginRes.data["user-details"].userLocale));
				dispatch(setUserEntity(loginRes?.data["user-details"]?.entity));
				loginSuccessAnalyticsCall(loginRes);
				navigateToSpecificPage(loginRes, user, authToken);
			}
		} else {
			setButtonLoader(false);
			if (loginRes && !loginRes.success && loginRes?.additionalProperties?.userLocked) {
				analytics?.trackEvent("login_error", { errorMessage: loginRes.reason });
				setLockoutError(true);
				setInvalidUser(false);
				if (loginRes.additionalProperties.userLocked) {
					setDisableAll(true);
				} else {
					setDisableAll(false);
				}
			} else if (loginRes?.reason?.toLowerCase() === "bad credentials" || loginRes?.reason?.toLowerCase() === "invalid username") {
				setInvalidUser(true);
			} else {
				setLockoutError(false);
				setDisableAll(false);
				setInvalidUser(false);
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loginRes, user?.authToken]);
	/* istanbul ignore next */
	const gotoSignUp = () => {
		analytics?.trackEvent("targeted_click", { engagementContext: "Create an account" });
		navigate("/");
	};
/* istanbul ignore next */
	const onLogin = (e) => {
		e?.preventDefault();
		let emailError = false;
		let passwordError = false;

		if (values.email === "") {
			emailError = true;
		}
		if (values.password === "") {
			passwordError = true;
		}
		setError({ ...error, email: emailError, password: passwordError });

		if (values.isChecked) localStorage.setItem("username", values.email);

		if (!emailError && !passwordError) {
			setLockoutError(false);
			setDisableAll(false);
			setInvalidUser(false);
			setButtonLoader(true);
			dispatch(loginService(values));
		}
	};
	/* istanbul ignore next */
	const handleChange = (prop) => (event) => {
		
		setValues({ ...values, [prop]: event.target.value });
		setError({ ...error, email: false, password: false });
	};
/* istanbul ignore next */
	useEffect(() => {
		setLockoutError(false);
		setDisableAll(false);
		setInvalidUser(false);
		setError({
			email: false,
			password: false,
		});
	}, [values]);
	
   /* istanbul ignore next */
	const getUserNameHelperText = () => {//NOSONAR
		let textTobeShown;
		if (error?.email) {
			textTobeShown = <div style={{ color: "#F44336" }}>{getLabel(config?.MANDATORY_CHECK)}</div>
		} else {
			textTobeShown = "";
		}
		return textTobeShown;
	}

	const token = Cookies?.get("token");

	if (config) {
		return (
			<>
				{(token && token != "undefined" && token != undefined) ? (
				<Loader/>
			) :
			<Box className="login_container">
				<Header screenType="login" />
				{lockoutError && loginRes.additionalProperties ? (
					<FXCMErrorHandling
						image={lockoutWarning}
						titleCondition={loginRes.additionalProperties.userLocked}
						title={config.ACCOUNTLOCKTITLE}
						attempsRemaining={config.ATTEMPTS_REMAINING}
						count={loginRes.additionalProperties.remainingAttempts}
						subTitleCondition={loginRes.additionalProperties.userLocked}
						subTitle={config.ACCOUNTLOCK_SUBTITLE}
						warning={config.LOCKOUT_WARNING}
					/>
				) : (
					""
				)}
				<Box
					sx={
						lockoutError
							? { mt: "30px" }
							: lockoutError && //NOSONAR
								(userEntity === "FXCM_EU" || userEntity === "FXCM_UK")
								? { mt: "70px" }
								: userEntity === "FXCM_EU" || userEntity === "FXCM_UK" //NOSONAR
									? { mt: "150px" }
									: { mt: "100px" }
					}
				>
					<div className="container-home">
						<Box textAlign="center">
							<img className="image-login" src={logInIcon} alt="logInIcon" />
							<Typography className={direction.status ? "heading-1_Arabic" : "heading-1"} data-testid="login-header">
								{getLabel(config.LOGIN_HEADING)}
							</Typography>
							<Typography className="subtext-cards" sx={{ mb: "8px" }} data-testid="login-subheader">
								{getLabel(config.LOGIN_SUBHEADER)}
							</Typography>
						</Box>

						<form onSubmit={onLogin}>
							<FormControl
								fullWidth
								sx={{ mt: 2 }}
								className={direction.status ? "custom-form-controler custom_form_controler_arabic loginUserName" : "custom-form-controler loginUserName"}
							>
								<TextField
									type="text"
									value={values.email}
									disabled={disableAll}
									onChange={handleChange("email")}
									error={error?.email || invalidUser}
									helperText={getUserNameHelperText()}
									label={getLabel("USERNAME")}

									InputProps={{
										"data-testid": "email-input",
									}}
								></TextField>
							</FormControl>
							<FormControl
								fullWidth
								sx={{ mt: 2 }}
								className={direction.status ? "custom-form-controler custom_form_controler_arabic" : "custom-form-controler"}
							>
								<TextField
									type={values.showPassword ? "text" : "password"}
									value={values.password}
									disabled={disableAll}
									onChange={handleChange("password")}
											error={error?.password || invalidUser}
									helperText={
										error.password ? getLabel(config.MANDATORY_CHECK) : ""
									}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													edge="end"
												>
													{values.showPassword ? (
														<span className="material-symbols-outlined">
															remove_red_eye
														</span>
													) : (
														<span className="material-symbols-outlined">
															visibility_off
														</span>
													)}
												</IconButton>
											</InputAdornment>
										),
										"data-testid": "password-input"
									}}
									label={getLabel("PASSWORD")}
								/>
							</FormControl>
							<FormControl className={direction.status ? "rememberMe_Arabic" : ""} data-testid="RememberMe">
								<FormControlLabel
									control={
										<Checkbox
											checked={values.isChecked}
											disabled={disableAll}
											onClick={handleClickRememberMe}
											size="small"
										/>
									}
									label={getLabel("REMEMBER_ME")}
										/>
							</FormControl>


									{invalidUser &&
										<><br />
											<FormControl fullWidth className={direction.status ? "custom-form-controler custom_form_controler_arabic" : "custom-form-controler"}>
												<FormHelperText>
													{getLabel("INVALID_LOGIN_CREDENTIALS")}
												</FormHelperText>
											</FormControl>
										</>
									}



							<LoadingButton
								loading={buttonLoader}
								loadingPosition="end"
								type="submit"
								fullWidth
								disabled={disableAll}
								variant="contained"
								sx={{ mt: "24px", mb: "18px" }}
								data-testid="LogIn_button"
							>
								{getLabel("LOG_IN")}
							</LoadingButton>
							<Box>
								<Grid container spacing={1} className={direction.status ? "ForgotButton_Arabic" : ""}>
									<Grid item xs={12}>
										<Button
											onClick={handleForgotUserName}
											fullWidth
											disabled={disableAll}
											variant="outline"
											sx={{ my: 1 }}
											data-testid="forgot_username_button"
										>
											{getLabel("FORGET_USERNAME")}
										</Button>
									</Grid>
									<Grid item xs={12}>
										<Button
											onClick={handleForgotPassword}
											fullWidth
											disabled={disableAll}
											variant="outline"
											sx={{ mt: 1 }}
											data-testid="forgot_password_button"
										>
											{getLabel("FORGET_PASSWORD")}
										</Button>
									</Grid>
								</Grid>
								<Typography
									textAlign="center"
									sx={{ mt: "12px", mb: "24px" }}
									className="login-bottom-text"
								>
									{getLabel("NOT_REGISTERED")}
									<span>
										<Link onClick={gotoSignUp} to="/">
											{getLabel("CREATE_AN_ACCOUNT")}
										</Link>
									</span>
								</Typography>
							</Box>
						</form>
					</div>
				</Box>
			</Box>
			}
			</>
		);
	}
};

export default LogInComponent;
