import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { expiryLinkIcon } from "../../constants/ImageConstants";
import { useTranslation } from "react-i18next";
import { configurationServices } from "../../constants/configurationServices";
import { useSelector } from "react-redux";

const ExpiredLink = () => {
	const { t: getLabel } = useTranslation();
	const [config, setConfig] = useState();
	const autoLoginMail = localStorage.getItem("autoLoginMail");
	const { expiredLinkValidationRes } = useSelector((state) => state.signUp);

	let expireEmailTobeShown = expiredLinkValidationRes?.data?.emailAddress;
	if (autoLoginMail) {/* istanbul ignore next */
		expireEmailTobeShown = autoLoginMail;
	}

	const fetchJson = () => {
		fetch(configurationServices().LogInURL)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				setConfig(data);
			})
			.catch((e) => {/* istanbul ignore next */
				console.log(e.message);
			});
	};

	useEffect(() => {
		fetchJson();
	}, []);

	return (
		<Box mt="69px">
			<Box sx={{ mt: 3 }}>
				<div className="container-home">
					<Box textAlign="center">
						<img className="image-login" src={expiryLinkIcon} alt="expiryLinkIcon" />
						<Typography className="expiry_header" sx={{ mt: "20px" }} data-testid="expiry_header">
							{getLabel(config?.LINK_EXPIRE)}
						</Typography>
						{
							expireEmailTobeShown ?
								<>
									<Typography className="expiry_text" sx={{ mt: "24px" }} data-testid="expiry_text">
										{getLabel(config?.EXPIRE_NOTIFY)}
									</Typography>
									<Typography className="expiry_email" sx={{ mt: "24px" }} data-testid="expiry_email">
										{expireEmailTobeShown}
									</Typography>
								</>
							: ""							
						}
					</Box>
				</div>
			</Box>
		</Box>
	)
}

export default ExpiredLink;