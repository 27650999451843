/* istanbul ignore file */
import axios from "axios";
import store from "../store";
import { resetAll } from "../reducers/resetSlice";
import Cookies from 'js-cookie';

export const customAxios = axios.create();
customAxios.interceptors.response.use((response) => {
	let requestURL = response?.request?.responseURL;
	if (!requestURL.includes("/login") && response?.data?.httpStatus === "UNAUTHORIZED") {
		Cookies.remove('token');
		localStorage.clear();
		store.dispatch(resetAll());
		window.location.replace("/login");
	} else {
		return response;
	}
}, (error) => {
	if (401 === error?.response?.status || error?.response?.data?.httpStatus === "UNAUTHORIZED") { 
		Cookies.remove("token");
		localStorage.clear();
		store.dispatch(resetAll());
		if (Cookies.get("token")) {
			window.location.replace("/login");
		} else {
			window.location.replace("/");
		}

	}
	return Promise.reject(error);
});
