import React, { useState, useEffect } from "react";
import UserMessage1 from "../../../components/UserMessage1";
import { userNameConfirmation } from "../../../constants/ImageConstants";
import { useNavigate, useLocation } from "react-router-dom";
import { configurationServices } from "../../../constants/configurationServices";
import { removeForgotPass } from "../../../reducers/signUpSlice";
import { useDispatch, useSelector } from "react-redux";
const ForgotUsernameConfirm = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const [data, setData] = useState("");
	const { forgotUsername } = useSelector((state) => state.signUp);

	const fetchData = () => {
		fetch(configurationServices().UserMessage)
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				setData(data);
			})
			.catch((e) => {
				console.log(e.message);
			});
	};
	useEffect(() => {
		fetchData();
		
	}, []);

	const handleClickUsername = () => {
		dispatch(removeForgotPass(forgotUsername));
		navigate("/login");
	};

	return (
		<UserMessage1
			image={userNameConfirmation}
			title={data.title}
			description={data.forgot_username_desc}
			buttonText={data.buttonText}
			handleClick={handleClickUsername}
			email={location.state.email}
		/>
	);
};

export default ForgotUsernameConfirm;