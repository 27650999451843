/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
	Grid,
	FormControl,
	Box,
	Typography,
	InputLabel,
	TextField,
	OutlinedInput,
	IconButton,
	InputAdornment,
	FormHelperText,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CardHeader } from "../../../components";
import "./style.scss";
import { configurationServices } from "../../../constants/configurationServices";
import { useDispatch, useSelector } from "react-redux";
import FXCMAutoComplete from "../../../components/common/FXCMAutoComplete";
import { authenticateUser } from "../../../services/auth.service";
import Header from "../../../components/layout/Header";
import { updateUserInfo } from "../../../reducers/userInfoSlice";
import { errorFlat } from "../../../constants/ImageConstants";
import {
	authenticationError,
	clearAuthResponse,
	getUserCurrentLocation,
	languagequeryParam,
	setCountryListInState,
	setUserEntity,
	arabicLanguagequeryParam
} from "../../../reducers/signUpSlice";
import FXCMErrorHandling from "../../../components/common/FXCMErrorHandling";
import { analytics } from "../../../utils/analytics/analytics";
import FXCMTrans from "../../../components/common/FXCMTrans";
import EsmaBanner from "../../../components/layout/EsmaBanner/EsmaBanner";
import { notifyApiFail } from "../../../reducers/errorNotifySlice";
import CardSubHeader from "../../../components/CardSubHeader";
import useDirectionHook from "../../../hooks/useDirectionHook";
import { 
	HAVING_NUMERIC, HAVING_UPPERCASE_LETTERS, HAVING_LOWERCASE_LETTERS, CUSTOM_EMAIL,
	ONLY_ALPHA_NUMERIC, SPECIAL_REGEX_FOR_EMAIL, CONSECUTIVE_REGEX, CHECK_LENGTH_MIN_8_MAX_15,
	HAVING_SPACE, CERTAIN_SPECIAL_CHARECTORS
} from "../../../utils/regex";
import Loader from "../../../components/Loader";
import Cookies from 'js-cookie';

const SignUpForm = () => {
	localStorage.removeItem("uPassToken");
	localStorage.removeItem("usermail");
	const preSelectedLocation = localStorage.getItem("PRE_SELECTED_COUNTRY");
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { t: getLabel } = useTranslation();
	const {
		getAllEnLabels,
		signUpRes,
		userEntity,
		authError,
		rbName,
		autoLoginResponse,
		loginRes,
		authRes, 
		authenticationResponse, 
		esmaText, 
		languageParam
	} = useSelector((state) => state.signUp);
	const { entityLanguageList } = useSelector((state) => state.common)
	const { currentCountry, ip } = useSelector((state) => state.ipAddress);
	const user = useSelector((state) => state.user);
	const emailRef = React.useRef();
	const [direction] = useDirectionHook(languageParam);

	const userData = user?.user || false;
	const savedCurrencyValue = user?.user?.denomination || null;
	const platform = user?.user?.platform || null;

	const [config, setConfig] = useState();
	const [countriesList, setCountriesList] = useState();
	const [entity, setEntity] = useState("");
	const [restrictedCountriesList, setRestrictedCountriesList] = useState();
	const [countryType, setCountryType] = useState("");
	const [fxcmError, setFxcmError] = useState(false);
	const [emailLengthError, setEmailLengthError] = useState(false);
	const [plateformData, setPlateformData] = useState([]);
	const [link, setLink] = useState();
	const [productDisclosureLink, setProductDisclosureLink] = useState("");
	const [financialGuideLink, setFinancialGuideLink] = useState("");
	const [termsOfBusinessLink, setTermsOfBusinessLink] = useState("");
	const [targetMarketLink, setTargetMarketLink] = useState("");
	const [currencyList, setCurrencyList] = useState();
	const [alreadyExistError, setAlreadyExistError] = useState(false);
	const [isFindLanguage, setIsFindLanguage] = useState(false);
	const [focus, setFocus] = useState(false);
	const [signupLoader, setSignupLoader] = useState(false);
	const [onboardingInComplete, setOnboardingInComplete] = useState(false);
	const [error, setError] = useState({
		email: false,
		password: false,
		country: false,
	});
	const [values, setValues] = React.useState({
		country: "",
		password: "",
		showPassword: false,
		email: "",
	});
	const [mandatoryError, setMandatoryError] = useState({
		email: false,
		password: false,
		country: false,
	});
	
	const queryParameters = new URLSearchParams(window.location.search);
	const campaignId = queryParameters.get("cmp");
	const product = queryParameters.get("product");
	const coreg = queryParameters.get("coreg");
	const urlLang = queryParameters.get("lang");

	useEffect(() => {
		fetchCountryJson();
		fetchFxcmRestrictedCountriesJson();
	}, []);

	useEffect(() => {
		fetchJson();
		if (userEntity && userEntity !== "FXCMNO") {/* istanbul ignore next */
			fetchJsonCurrency();/* istanbul ignore next */
			fetchJsonPlatForm();
		}
	}, [userEntity]);
 

	const fetchJson = () => {
		fetch(configurationServices(userEntity).signUpURL)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				setConfig(data);
			})
			.catch((e) => {/* istanbul ignore next */
				console.log(e.message);
			});
	};
/* istanbul ignore next */
	useEffect(() => {
		if (config && userEntity && userEntity !== "FXCMNO") {
			let data = config;
			const filteredproduct_Link = data?.PRODUCT_DISCLOSURE_URL?.filter(
				(value) => value.locale === languageParam)?.[0]?.link;

			const filteredFinancial_Link = data?.FINANCIAL_SERVICE_URL?.filter(
				(value) => value.locale === languageParam)?.[0]?.link;

			const filteredTOB_Link = data?.TERM_OFBUSINESS_URL?.filter(
				(value) => value.locale === languageParam)?.[0]?.link;

			const filteredTarget_Link = data?.TARGET_MARKET_URL?.filter(
				(value) => value.locale === languageParam)?.[0]?.link;

			const filteredDenominationArray = data?.RISK_DISCLOSURE_URL?.filter(
				(value) => value.locale === languageParam)?.[0]?.link;

			setProductDisclosureLink(filteredproduct_Link);
			setFinancialGuideLink(filteredFinancial_Link);
			setTermsOfBusinessLink(filteredTOB_Link);
			setTargetMarketLink(filteredTarget_Link);
			setLink(filteredDenominationArray);
		}
	}, [languageParam, config]);

	const fetchFxcmRestrictedCountriesJson = () => {
		fetch(configurationServices(userEntity).restrictedCountriesURL)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				setRestrictedCountriesList(data);
			})
			.catch((e) => {/* istanbul ignore next */
				console.log(e.message);
			});
	};

	const fetchCountryJson = () => {
		let countriesList = configurationServices(userEntity).countryList;
		fetch(countriesList)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let location = (autoLoginResponse?.success && loginRes?.data?.["user-details"]?.countryOfResidence) ? 
					loginRes.data["user-details"].countryOfResidence : currentCountry;

				let filterSelCountryCode = data.filter(/* istanbul ignore next */
					(item) => item.country_code === location)?.[0]?.entity_code;
					/* istanbul ignore next */
				if (languageParam !== "ar-AE") {/* istanbul ignore next */
					dispatch(setUserEntity(filterSelCountryCode));
				}/* istanbul ignore next */
				setCountriesList(data);
			})
			.catch((e) => {
				console.log(e.message);
			});
	};

	const fetchJsonPlatForm = () => {/* istanbul ignore next */
		fetch(configurationServices(userEntity).platformURL)
			.then((response) => {/* istanbul ignore next */
				return response.json();
			})
			.then((data) => {/* istanbul ignore next */
				setPlateformData(data.list);
			})
			.catch((e) => {/* istanbul ignore next */
				console.log(e.message);
			});
	};

	const fetchJsonCurrency = () => {/* istanbul ignore next */
		fetch(configurationServices(userEntity).CurrencyURL)
			.then((response) => {/* istanbul ignore next */
				return response.json();
			})
			.then((data) => {/* istanbul ignore next */
				setCurrencyList(data);
			})
			.catch((e) => {/* istanbul ignore next */
				console.log(e.message);
			});
	};

	useEffect(() => {
		dispatch(setCountryListInState(countriesList));
	}, [countriesList]);

	const getCountryNameFromCountryCode = (countryCode) => {/* istanbul ignore next */
		if (countryCode && countriesList?.length) {/* istanbul ignore next */
			const findCountry = countriesList.find((element)=>element?.country_code === countryCode)/* istanbul ignore next */
			return findCountry?.country_desc;
		}/* istanbul ignore next */
		return countryCode;
	}

	useEffect(() => {
		if (authRes && !autoLoginResponse?.success) {/* istanbul ignore next */
			setSignupLoader(false);/* istanbul ignore next */
			setAlreadyExistError(false);/* istanbul ignore next */
			setOnboardingInComplete(false);/* istanbul ignore next */
			analytics?.trackEvent("onboarding_form_start", {
				onboardingStep: "Step 1",
				formEmail: authenticationResponse?.["signup-response"]?.users?.[0]?.username,
				formCountry: values.countryDesc ? values.countryDesc : getCountryNameFromCountryCode(userData?.countryOfResidence),
				engagementContext: "Create an account"
			});
			let useinfoData = {
				userName: authenticationResponse?.["signup-response"]?.users?.[0]?.username,
				email: values.email ? values.email : userData.email,
				countryOfResidence: values.country
					? values.country
					: userData.countryOfResidence,
				locale: localStorage.getItem("i18nextLng"),
				authToken: authenticationResponse?.["login-response"]?.token || "",
			};/* istanbul ignore next */
			dispatch(updateUserInfo(useinfoData));/* istanbul ignore next */
			if (!rbName) {/* istanbul ignore next */
				navigate("/platform");
			}
		}
	}, [
		authRes,
		values,
		platform,
		plateformData,
		authenticationResponse,
		currencyList,
		getAllEnLabels,
		savedCurrencyValue,
		userData.countryOfResidence,
		userData.userName,
		userData.email,
		rbName
	]);

	const handleClickShowPassword = () =>{
		/* istanbul ignore next */
		setValues({
			...values,
			showPassword: !values.showPassword,
		});
	};

	const handleMouseDownPassword = (event) => {/* istanbul ignore next */
		event.preventDefault();
	};
/* istanbul ignore next */
	const handleChange = (prop) => (event) => {
		if (prop === "password") {
			setMandatoryError({ ...mandatoryError, password: false });
		}
		setValues({ ...values, [prop]: event.target.value });
		if (prop === "email") {
			setAlreadyExistError(false);
		}
	};
/* istanbul ignore next */
	const handleBlurEmail = () => {
		const emailRegex = CUSTOM_EMAIL.test(values.email);
		const onlyAlphanumeric = ONLY_ALPHA_NUMERIC.test(values.email);
		const specialRegex = SPECIAL_REGEX_FOR_EMAIL.test(values.email);
		const consecutiveRegex = CONSECUTIVE_REGEX.test(values.email);
		setAlreadyExistError(false);
		setFxcmError(false);
		setMandatoryError({ ...mandatoryError, email: false });
		if (emailRegex && specialRegex && consecutiveRegex && !onlyAlphanumeric) {
			setError({ ...error, email: false });
		} else {
			setError({ ...error, email: true });
		}

		if (values.email.length > config?.emailMaxLength) {
			setError({ ...error, email: true });
			setEmailLengthError(true);
		} else {
			setEmailLengthError(false);
		}
	};
/* istanbul ignore next */
	const handleBlurPassword = () => {
		const isHavingSpace = HAVING_SPACE.test(values.password);
		const isHavingCertainSpeChar = CERTAIN_SPECIAL_CHARECTORS.test(values.password);
		setFocus(true);
		setMandatoryError({ ...mandatoryError, password: false });
		if (
			(!isHavingSpace && !isHavingCertainSpeChar) &&
			HAVING_UPPERCASE_LETTERS.test(values.password) &&
			HAVING_NUMERIC.test(values.password) &&
			HAVING_LOWERCASE_LETTERS.test(values.password) &&
			CHECK_LENGTH_MIN_8_MAX_15.test(values.password)
		) {
			setError({ ...error, password: false });
		} else {
			setError({ ...error, password: true });
		}
	};

	const validation = () => {
		let isValid = true;/* istanbul ignore next */
		if (error.password || error.email || error.country) {/* istanbul ignore next */
			isValid = false;
		}
		if (
			values.email === "" ||
			values.password === "" ||
			values.country === ""
		) {/* istanbul ignore next */
			isValid = false;
		}/* istanbul ignore next */
		return isValid;
	};
/* istanbul ignore next */
	const formSubmit = (e) => {
		e.preventDefault();
		const isEmailEmpty = values.email === "";
		const isCountryEmpty = values.country === "";
		const isPasswordEmpty = values.password === "";
		setMandatoryError({
			...mandatoryError,
			email: isEmailEmpty,
			password: isPasswordEmpty,
			country: isCountryEmpty
		});
		if (validation()) {
			window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
			setValues({ ...values });
			setSignupLoader(true);

			const trackingId = window?.__fxcmGtm?.get?.CLIENTID;
			const gclId = window?.__fxcmGtm?.get?.GCLID;
			dispatch(authenticateUser(values, esmaText, ip, rbName, campaignId, product, languageParam, trackingId, coreg, gclId, Cookies));
		}
	};
/* istanbul ignore next */
	useEffect(() => {
        if (authError) {
            const handleUserNotWhitelistedError = () => {
                setError({ ...error, email: true });
                setFxcmError(true);
            }
 
            const handleUserEmailAlreadyExistOrAccountLockedFxma = () => {
                setError({ ...error, email: true });
                setAlreadyExistError(true);
                dispatch(
                    notifyApiFail({
                        title: getLabel("ADDITIONAL_ACCOUNT"),
                        desc: getLabel("ADDITIONAL_ACCOUNT_DESC"),
                        type: "warning",
                    })
                );
                setTimeout(() => {
                    dispatch(authenticationError({}));
                }, 500);
            }
           
            const handleUserOnboardingIncompleteOrAccountLockedCxLogin = () => {
                setError({ ...error, email: true });
                setAlreadyExistError(true);
                setOnboardingInComplete(true);
                dispatch(
                    notifyApiFail({
                        title: getLabel("EMAIL_EXIST"),
                        desc: getLabel("EMAIL_EXIST_DESCRIPTION"),
                        type: "warning",
                    })
                );
                setTimeout(() => {
                    dispatch(authenticationError({}));
                }, 500);
            }
 
            const handleRbNotAllowedError = () => {
                setCountryType("rb_cor_error");
                setError({ ...error, country: true });
                if (languageParam === "zh-TW" || languageParam === "zh-CN") {
                    window.location.href = "https://account.fuhuicorporate.com/";
                } else {
                    window.location.href = "https://account.fxcm.com/";
                }
                dispatch(clearAuthResponse());
            }
 
            switch(authError.message) {
                case "USER_NOT_WHITELISTED_ERROR":
                    handleUserNotWhitelistedError();
                    break;
                case "USER_EMAIL_ALREADY_EXIST":
                case "USER_ACCOUNT_LOCKED_FXMA":
                    handleUserEmailAlreadyExistOrAccountLockedFxma();
                    break;
                case "USER_ONBOARDING_INCOMPLETE":
                case "USER_ACCOUNT_LOCKED_CX_LOGIN":
                    handleUserOnboardingIncompleteOrAccountLockedCxLogin();
                    break;
                case "RB not allowed":
                    handleRbNotAllowedError();
                    break;
                default:
                    break;
            }
 
            setSignupLoader(false);
        }
    }, [authError]);

	useEffect(() => {
		if (entity && languageParam !== "ar-AE") {/* istanbul ignore next */
			dispatch(setUserEntity(entity));
		}
	}, [entity]);

	useEffect(() => {
		const findLanguage = entityLanguageList?.find((lang) => lang.key === languageParam);
		const isFindLanguageBool = Boolean(findLanguage);
		setIsFindLanguage(isFindLanguageBool);
	}, [entityLanguageList, languageParam]);
/* istanbul ignore next */
	useEffect(() => {
		if (userEntity) {
				fetch(configurationServices(userEntity).languageListURL)
				.then((response) => {
					return response.json();
				})
					.then((languageList) => {
						if (direction.status === false) {
							if (urlLang) {
								const languageDetails = languageList[urlLang];
								let isLanguagePresent = Boolean(languageDetails);
								if (isLanguagePresent) {
									dispatch(languagequeryParam(urlLang));
								} else {
									dispatch(languagequeryParam("en-US"));
								}
							} else {
								const languageDetails = languageList[languageParam];
								let isLanguagePresent = Boolean(languageDetails);
								if (isLanguagePresent) {
									dispatch(languagequeryParam(languageParam));
								} else {
									dispatch(languagequeryParam("en-US"));
								}
							}
						} else {
							dispatch(languagequeryParam("ar-AE"));
						}
			})
			.catch((e) => {
				console.log(e.message);
			});
			
		}
	}, [userEntity])
/* istanbul ignore next */
	useEffect(() => {
		if (!autoLoginResponse) {
			if (direction.status === false) {
				if (!isFindLanguage) {
					dispatch(languagequeryParam("en-US"));
				} else {
					dispatch(languagequeryParam(localStorage.getItem("i18nextLng")));
				}
				if (values?.country === "DE") {
					dispatch(languagequeryParam("de-DE"));
				}
			} else {
				dispatch(languagequeryParam("ar-AE"))
			}
		}

	}, [isFindLanguage, direction.status, languageParam])
/* istanbul ignore next */
	const handleClickMain = (value) => {
        const handleCountrySelection = (value) => {
            dispatch(arabicLanguagequeryParam(false));
            localStorage.setItem("PRE_SELECTED_COUNTRY", value.country_code);
            dispatch(setUserEntity(value.entity_code));
            setMandatoryError({ ...mandatoryError, country: false });
            setValues({
                ...values,
                country: value.country_code,
                countryDesc: value.country_desc,
                entity: value.entity_code,
            });
            setEntity(value.entity_code);
            dispatch(getUserCurrentLocation(value.country_code));
            emailRef.current.focus();
            if (value.entity_code !== "FXCM_SVG" || value.entity_code !== "FXCMNO") {
                direction.status = false;
            }
 
            if (value.country_code === "DE") {
                dispatch(languagequeryParam("de-DE"));
            }
        }

        const handleCountryRestriction = (value) => {
            const findCountryrestricted = restrictedCountriesList?.restricted.find(
                (item) => item === value.country_code
            );
            if (findCountryrestricted) {
                setError({ ...error, country: true });
                setCountryType("restricted");
            } else {
                const findMisc = restrictedCountriesList?.misc.find(
                    (item) => item === value.country_code
				);
				const traduAppLink = process.env.REACT_APP_TRADU_LINK;
				let traduRedirect = false;
				/*if (window.location.origin.includes("fxcm.com")) {
					if (traduAppLink && traduAppLink !== "" && traduAppLink !== null) {
						traduRedirect = restrictedCountriesList?.traduRedirect.find(
							(item) => item === value.country_code
						);
					}
				}*/
				if (findMisc) {
                    setError({ ...error, country: true });
                    setCountryType("misc");
				} else if (traduRedirect) {
					setError({ ...error, country: true });
					setCountryType("traduRedirect");
				} else {
                    setError({ ...error, country: false });
                }
            }
        }
 
        if (value) {
            handleCountrySelection(value)
        } else {
            setValues({ ...values, country: "" });
        }
 
        if (value?.entity_code) {
            handleCountryRestriction(value)
        } else {
            setError({ ...error, country: false });
        }
    };
/* istanbul ignore next */
	useEffect(() => {
		if (preSelectedLocation !== "" && preSelectedLocation && countriesList !== undefined) {
			const preSelectedCountryObject = countriesList?.filter((data) => data.country_code === preSelectedLocation)?.[0];
			handleClickMain(preSelectedCountryObject);
		}
	}, [preSelectedLocation]);

	const handleClick = (value) => {/* istanbul ignore next */
		handleClickMain(value);
	};
/* istanbul ignore next */
	const handleOnKeyDown = () => {
		const isHavingSpace = HAVING_SPACE.test(values.password);
		const isHavingCertainSpeChar = CERTAIN_SPECIAL_CHARECTORS.test(values.password);
		console.log((!isHavingSpace && !isHavingCertainSpeChar), !isHavingSpace, !isHavingCertainSpeChar)
		if (
			(!isHavingSpace && !isHavingCertainSpeChar) &&
			HAVING_UPPERCASE_LETTERS.test(values.password) &&
			HAVING_NUMERIC.test(values.password) &&
			HAVING_LOWERCASE_LETTERS.test(values.password) &&
			CHECK_LENGTH_MIN_8_MAX_15.test(values.password)
		) {
			setError({ ...error, password: false });
		}
	};

	const handleOnfocus = () => {/* istanbul ignore next */
		setFocus(false);
	};

	const getJSXHelperText = () => {
		if (fxcmError) {/* istanbul ignore next */
			return (
				<div style={{ color: "#F44336" }}>
				<FXCMTrans defaults={getLabel(config?.EmailFXCMERROR)} />
				</div>
			);
		}
		if (error.email && !error.country && alreadyExistError) {
			return (
				<div style={{ color: "#F44336" }}>
					<FXCMTrans
						defaults={getLabel("EMAIL_ALREADY_EXIST")}
						values={{
							url: onboardingInComplete ? "/login" : "https://www.myfxcm.com/fxma/loginbp",
						}}
					/>
				</div>
			);
		}
		return null;
	  };
	  
	  const getTextHelperText = () => {
			if (emailLengthError) {/* istanbul ignore next */
				return getLabel(config?.emailLenghtError);
			}
			if (mandatoryError.email) {/* istanbul ignore next */
				return getLabel(config?.MANDATORY_CHECK);
			}
			if (error.email && !error.country && !alreadyExistError) {/* istanbul ignore next */
				return getLabel(config?.EmailErrorText);
			}
			return "";
	  };

	const validatePasswordLength = () => {
		if (CHECK_LENGTH_MIN_8_MAX_15.test(values.password)) {/* istanbul ignore next */
			return true;
		} else if(focus || values.password.length > 15) {/* istanbul ignore next */
			return false;
		}
		return null
	}

	const validatePasswordContainsUpperAndLowerCase = () => {
		const isHavingLowerCase = HAVING_LOWERCASE_LETTERS.test(values.password);
		const isHavingUpperCase = HAVING_UPPERCASE_LETTERS.test(values.password);
		if (isHavingLowerCase && isHavingUpperCase) {/* istanbul ignore next */
			return true
		} else if (focus) {/* istanbul ignore next */
			return false
		}
		return null
	}

	const validatePasswordContainsNumeric = () => {
		const isHavingNumeric = HAVING_NUMERIC.test(values.password);
		if (isHavingNumeric) {/* istanbul ignore next */
			return true
		} else if (focus) {/* istanbul ignore next */
			return false
		}
		return null
	}

	const validatePasswordDoesNotContainsSpacesAndCertainSpecialCharectors = () => {
		const isHavingSpace = HAVING_SPACE.test(values.password);
		const isHavingCertainSpeChar = CERTAIN_SPECIAL_CHARECTORS.test(values.password);
		return isHavingSpace || isHavingCertainSpeChar;
	}

	const isPasswordLengthValid = validatePasswordLength();
	const isPasswordContainsBothCases = validatePasswordContainsUpperAndLowerCase();
	const isPasswordContainsNumeric = validatePasswordContainsNumeric();
	const isHavingSpaceOrSpeChar = validatePasswordDoesNotContainsSpacesAndCertainSpecialCharectors();
	const getHelperText = getJSXHelperText() || getTextHelperText();
	const enabled = error.country;

	if (!config || !restrictedCountriesList) {
        return <Loader />;
    }
	/* istanbul ignore next */
	const isEuOrUkUser = (userEntity === "FXCM_EU" || userEntity === "FXCM_UK");
	const renderErrorHandling = () => {/* istanbul ignore next */
        if (signUpRes && !signUpRes.success) {
            const title = (signUpRes?.status === 403) ? getLabel("SOMETHING_WENT_WRONG") : signUpRes.error || "";
			const subTitle = (signUpRes?.status === 403) ? getLabel("PLEASE_TRY_AGAIN_AFTER_SOMETIME") : getLabel("SOMETHING_WENT_WRONG") || "";
            return (
                <FXCMErrorHandling
                    titleCondition={true}
                    title={title}
                    subTitleCondition={true}
                    image={errorFlat}
                    column={true}
                    PaddingTop={true}
                    subTitle={subTitle}
                />
            );
        }/* istanbul ignore next */
        return null;
    };

	const renderPasswordValidation = () => {/* istanbul ignore next */
        if (error.password || values.password) {
			const validationItems = [
				{ condition: isPasswordLengthValid, message: getLabel(config.PASSWORD_LENGTH) },
				{ condition: isPasswordContainsNumeric, message: getLabel(config.PASSWORD_MIXIN) },
				{ condition: isPasswordContainsBothCases, message: getLabel(config.PASSWORD_CONTAIN) }
			];
            return (
                <Box className="password-validation-wrapper" sx={error.password ? { background: "#FDF0F2", p: 1.5, mt: 2 } : { background: "#fafafa", p: 1.5, mt: 2 }}>
                    <Typography className="default font-14">{getLabel(config.PASSWORD_MUST)}</Typography>
                    <ul className={direction.status ? "passowrd-validation-arabic" : ""}>
                        {validationItems.map(({ condition, message }) => (/* istanbul ignore next */
                            <li key={message} className={`default ${condition ? "greenTick" : "redCross"}`}>
                                {message}
                            </li>
                        ))}
						{
							isHavingSpaceOrSpeChar ? <li className="redCross">{getLabel(config.PASSWORD_SPACES_OR_SPECIAL)}</li> : ''
						}
                    </ul>
                </Box>
            );
        }/* istanbul ignore next */
        return null;
    };

	const renderCountryField = () => (/* istanbul ignore next */
		<FormControl fullWidth sx={{ mt: 4 }}>
			<FXCMAutoComplete
				label={getLabel(config.countryLabel)}
				options={countriesList}
				handleClick={handleClick}
				disabled={enabled}
				sx={{ backgroundColor: enabled ? "#F0F0F0" : "" }}
				cssStyles={`
					custom-Autocomplete-wraaper valueAutoComplete1
					${error?.country && "valueAutoComplete auto-display-error"} 
					${direction?.status && "custom-Autocomplete-wraaper_arabic"} 
					${(error?.country && direction?.status) && "valueAutoComplete_Arabic"}
				`}
				textFieldSize="small"
				error={ mandatoryError.country || error.country || false }
				errorMessage={(() => {/* istanbul ignore next */
					if (mandatoryError?.country) {
						return getLabel(config?.MANDATORY_CHECK);
					} /* istanbul ignore next */
					if (error.country) {/* istanbul ignore next */
						return countryType;
					} /* istanbul ignore next */
					return "";
					})()}
				restrictedCountries={restrictedCountriesList}
				currentLocation={currentCountry}
				preSelectedLocation={preSelectedLocation}
				screenType="signup"
			/>
		</FormControl>
	)

	const renderEmailField = () => (
		<FormControl
			fullWidth
			sx={{ mt: 2 }}
			className={
				direction.status
					? "custom-form-controler custom_form_controler_arabic"
					: "custom-form-controler"
			}
		>
			<TextField
				value={values.email}
				onChange={handleChange("email")}
				name="email"
				inputRef={emailRef}
				inputProps={{ maxLength: 61 }}
				disabled={enabled}
				sx={{ backgroundColor: enabled ? "#F0F0F0" : "" }}
				onBlur={handleBlurEmail}
				error={error.email && !error.country}
				helperText={getHelperText}
				label={getLabel(config.emailLebel)}
				InputProps={{
					"data-testid": "email-input",
				}} 
				InputLabelProps={{
					style: { backgroundColor: enabled ? "#F0F0F0" : "" },
				}}
				variant="outlined"
			/>
		</FormControl>
	)

	const renderPasswordField = () => (
		<FormControl
			fullWidth
			sx={{ mt: 2 }}
			variant="outlined"
			className={`custom-form-controler ${error.password && "error-password"} ${direction.status && "custom_form_controler_arabic"}`}
		>
			<InputLabel
				htmlFor="password-input"
				disabled={enabled}
				style={{ background: enabled ? "#F0F0F0" : "" }}
			>
				{getLabel(config.passwordLabel)}
			</InputLabel>
			<OutlinedInput
				id="password-input"
				type={values.showPassword ? "text" : "password"}
				value={values.password}
				disabled={enabled}
				sx={{ backgroundColor: enabled ? "#F0F0F0" : "" }}
				onChange={handleChange("password")}
				onBlur={handleBlurPassword}
				onFocus={handleOnfocus}
				onKeyDown={handleOnKeyDown}
				error={Boolean(error.password)}
				endAdornment={
					<InputAdornment position="end">
						{!enabled ? (
							<IconButton
								aria-label="toggle password visibility"
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
								disabled={enabled}
								edge="end"
								data-testid="toggle-password-visibility"
							>
								{values.showPassword ? (
									<span className="material-symbols-outlined">
										remove_red_eye
									</span>
								) : (
									<span className="material-symbols-outlined">
										visibility_off
									</span>
								)}
							</IconButton>
						) : (
							""
						)}
					</InputAdornment>
				}
				label="Password"
			/>
			{(mandatoryError?.password) ? (
				<FormHelperText>
					{getLabel(config.MANDATORY_CHECK)}
				</FormHelperText>
			) : ''}
			{renderPasswordValidation()}
		</FormControl>
	)
	
	return (
		<div className={isEuOrUkUser ? "customer-header2" : ""}>
			{isEuOrUkUser ? <EsmaBanner key="esmaBannerId" /> : ""}
			<Header country={values.country}/>
			{renderErrorHandling()}
			<Box sx={{ mt: isEuOrUkUser ? "142px" : "102px"}}>
				<div className="container-home">
					<Grid>
						<Grid item sx={{ textAlign: "center", md: 5 }}>
							<form onSubmit={formSubmit}>
								<CardHeader title={getLabel(config.loginHeader)} data-testid="SignUp_header" />
								<div className="sub-header">
									<CardSubHeader value={getLabel(config.SIGNUP_SUBHEADING)} data-testid="SignUp_SubHeader" />
								</div>
								{renderCountryField()}
								{renderEmailField()}
								{renderPasswordField()}
								<Typography
									align="center"
									sx={{ my: 2 }}
									className={
										(direction?.status) ? "sub-text_Arabic" : "sub-text"
									}
								>
									{getLabel(config.SIGN_UP_NOTE)}
								</Typography>
								<LoadingButton
									loading={signupLoader}
									loadingPosition="end"
									type="submit"
									disabled={enabled}
									style={{ backgroundColor: enabled ? "#F0F0F0" : "" }}
									fullWidth
									variant="contained"
								>
									{getLabel("SIGN_UP")}
								</LoadingButton>
							</form>
							<Typography
								className="link-text"
								align="center"
								sx={{ my: 2, p: 0.5 }}
								md={{ p: 0 }}
							>
								<FXCMTrans
									defaults={getLabel("ALREADY_HAVE_ACCOUNT")}
									values={{ url: "/login" }}
								></FXCMTrans>
							</Typography>
							{config?.FOOTER_COPYRIGHT && (
								<div className="Footer_text">
									<Typography
										className={
											(direction?.status) ? "sub-text_Arabic" : "sub-text"
										}
									>
										<FXCMTrans
											defaults={getLabel(config.FOOTER_COPYRIGHT)}
											values={{
												year: new Date().getFullYear(),
												url: link,
												targetPage: true,
												product_disclosure_statement: productDisclosureLink,
												financial_services_guide: financialGuideLink,
												terms_of_business: termsOfBusinessLink,
												target_market_determination: targetMarketLink
											}}
										/>
									</Typography>
								</div>
							)}
						</Grid>
					</Grid>
				</div>
			</Box>
		</div>
	);
};

export default SignUpForm;
