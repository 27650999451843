/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { resetAll } from "./resetSlice";

const initialState = {
	user: {
		userName: "",
		email: "",
		countryOfResidence: "",
		locale: "",
		authToken: "",
		platform: null,
	},
};

const userInfoSlice = createSlice({
	name: "userInfo",
	initialState,
	reducers: {
		updateUserInfo: (state, action) => {
			const payload = action.payload;
			state.user = { ...state.user, ...payload };
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetAll, () => {
		  return userInfoSlice.getInitialState;
		});
	  },
});

export const { updateUserInfo } = userInfoSlice.actions;
export default userInfoSlice.reducer;
