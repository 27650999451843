import React from "react";
import { AutocompleteCustomEndAdornment } from "../components/FormCard/components/AutocompleteCustomEndAdornment";
import { IconButton } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import DoneIcon from "@mui/icons-material/Done";

const EndAdornment = ({
    item,
    openRefSelectBox,
    openSelectBoxItem,
    selectedAUState,
    auInfo,
    openReferenceSelectBox,
    openSelectBox
}) => {
  return (
    <AutocompleteCustomEndAdornment
      onClick={
        item.fieldName === "referenceNumber"
          ? () => openRefSelectBox(item.fieldName)
          : () => openSelectBoxItem(item.fieldName)
      }
    >
      <IconButton disableRipple size="small">
        {((selectedAUState && auInfo?.state) || auInfo?.medicare) &&
        ["state", "medicare"].includes(item.fieldName) ? (
          <DoneIcon className="doneIcon" sx={{ color: "#20C374" }} />
        ) : null}
        {auInfo?.medicare &&
        auInfo?.referenceNumber &&
        ["referenceNumber"].includes(item.fieldName) ? (
          <DoneIcon className="doneIcon" sx={{ color: "#20C374" }} />
        ) : null}
        {(auInfo?.passportCountry) &&
        ["passportCountry"].includes(item.fieldName) ? (
        <DoneIcon className="doneIcon" sx={{ color: "#20C374" }} />
        ) : null}
      </IconButton>
      <IconButton disableRipple size="small" style={{ paddingLeft: "0px" }}>
        {openReferenceSelectBox || openSelectBox ? (
          <ArrowDropUpIcon sx={{ fontSize: "18px" }} />
        ) : (
          <ArrowDropDownIcon sx={{ fontSize: "18px" }} />
        )}
      </IconButton>
    </AutocompleteCustomEndAdornment>
  );
};

export default EndAdornment;
