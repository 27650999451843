const environment = process.env.NODE_ENV;
let CDN_URL = ""; //process.env.REACT_APP_CDN_URL; //temporary
if (environment === "production") {
	CDN_URL = process.env.REACT_APP_CDN_URL;
}

export const imageFolder = CDN_URL + "/images/";
export const iconFolder = CDN_URL + "/images/icons/";
export const optionIconFolder = CDN_URL + "/images/icons/option-icon/";

export const infoImage = CDN_URL + "/images/info_24px.svg";
export const alert = CDN_URL + "/images/alert.png";
export const appleIcon = CDN_URL + "/images/apple-icon.svg";
export const appleLogo = CDN_URL + "/images/apple-logo.svg";
export const arrowUp = CDN_URL + "/images/arrow-up.svg";
export const backButton = CDN_URL + "/images/back-button.svg";
export const chat = CDN_URL + "/images/chat.svg";
export const CheckCircle = CDN_URL + "/images/CheckCircle.png";
export const chromeIcon = CDN_URL + "/images/chrome-icon.svg";

export const currencyIcon = CDN_URL + "/images/currency-icon.svg";
export const desktopIcon = CDN_URL + "/images/desktop-icon.svg";
export const Flag1 = CDN_URL + "/images/Flag1.png";
export const Flag2 = CDN_URL + "/images/Flag2.png";
export const Flag3 = CDN_URL + "/images/Flag3.png";
export const Flag4 = CDN_URL + "/images/Flag4.png";
export const googleLogo = CDN_URL + "/images/google-logo.svg";
export const handshake = CDN_URL + "/images/handshake.svg";

export const home = CDN_URL + "/images/home.svg";
export const imagePic = CDN_URL + "/images/image-pic.png";
export const infoIcon = CDN_URL + "/images/info-icon.svg";
export const Logo = CDN_URL + "/images/Logo.svg";
export const mail = CDN_URL + "/images/mail.svg";
export const phone = CDN_URL + "/images/phone.svg";
export const plateformIcon_1 = CDN_URL + "/images/plateform-icon-1.svg";
export const plateformIcon_2 = CDN_URL + "/images/plateform-icon-2.svg";

export const plateformIcon_3 = CDN_URL + "/images/plateform-icon-3.svg";
export const plateformIcon_4 = CDN_URL + "/images/plateform-icon-4.svg";
export const playstoreIcon = CDN_URL + "/images/playstore-icon.svg";
export const RadioButtonUnchecked =
	CDN_URL + "/images/RadioButtonUnchecked.png";
export const RiskImage = CDN_URL + "/images/RiskImage.svg";
export const searchIcon = CDN_URL + "/images/search-icon.svg";
export const signeddocuments = CDN_URL + "/images/signeddocuments.svg";
export const worldIcon = CDN_URL + "/images/world-icon.svg";
export const worldIconWhite = CDN_URL + "/images/world-icon-white.svg";

export const logInIcon = CDN_URL + "/images/logInIcon.svg";
export const openInNewTab = CDN_URL + "/images/open_in_new.png";
export const password = CDN_URL + "/images/securePass.svg";
export const Email = CDN_URL + "/images/Email.svg";
export const passwordIcon = CDN_URL + "/images/securePassIcon.svg";
export const warning = CDN_URL + "/images/warning.svg";
export const lockoutWarning = CDN_URL + "/images/lockwarning.svg";
export const fxcmLoader = CDN_URL + "/images/fxcm.svg";
export const liveLogo = CDN_URL + "/images/live-logo.svg";
export const MenuIcon = CDN_URL + "/images/menu_24px.svg";
export const CloseIcon = CDN_URL + "/images/closeIcon.svg";
export const userIcon = CDN_URL + "/images/forgot-username.svg";
export const expiryLinkIcon = CDN_URL + "/images/expiry-link.svg";
export const arrowUpward = CDN_URL + "/images/arrow_upward_24px.svg";
export const notificationIcon = CDN_URL + "/images/notifiIcon.svg";
export const ClosePopUp = CDN_URL + "/images/IconButton.svg";
export const errorFlat = CDN_URL + "/images/icons/1140-error-flat.svg";
export const unsuccessfulWarning = CDN_URL + "/images/unsuccessful-warning.svg";
export const ErrorIcon = CDN_URL + "/images/error.svg";

export const Flag5 = CDN_URL + "/images/Flag5.png";
export const Flag6 = CDN_URL + "/images/Flag6.png";
export const CheckCircleIcon = CDN_URL + "/images/check-circle-icon.png";
export const FXCMfundsicon = CDN_URL + "/images/fxcm-funds.png";
export const FundsLogo = CDN_URL + "/images/funds-logo.png";
export const CopyIcon = CDN_URL + "/images/copy-content.png";
export const UploadBox = CDN_URL + "/images/UploadBox.svg";
export const SavingsIcon = CDN_URL + "/images/SAVINGS-PIG.svg";
export const uploadIcon = CDN_URL + "/images/icons/uploadIcon.svg";
export const licenseImageFolder = CDN_URL + "/images/icons/aus-states/";
export const mediImageFolder = CDN_URL + "/images/icons/medi-cards/"
export const userName = CDN_URL + "/images/Username.svg";
export const userNameConfirmation =
	CDN_URL + "/images/UsernameConfirmation.svg";

export const getFxcmLogo = (lang) => {
	let data = CDN_URL + `/configurations/fxcmLogo/${lang}/newlogo.svg`;
	return data;
};
